const typeMap: Record<string, string> = {
  '3_4_front': 'data.pictures.types.options.3_4_front',
  'back': 'data.pictures.types.options.back',
  'bed_1': 'data.pictures.types.options.bed_1',
  'bed_2': 'data.pictures.types.options.bed_2',
  'cockpit': 'data.pictures.types.options.cockpit',
  'dining_area': 'data.pictures.types.options.dining_area',
  'kitchenette': 'data.pictures.types.options.kitchenette',
  'other': 'data.pictures.types.options.other',
  'shower_int': 'data.pictures.types.options.shower_int',
  'bathroom': 'data.pictures.types.options.bathroom',
}

export const useGetTranslatedPictureType = () => {
  const { $i18n: { t } } = useNuxtApp()

  return {
    getTranslatedPictureType: (type: string | null | undefined) => {
      if (!type) {
        return null
      }

      const key = typeMap[type.toLowerCase()]
      return key ? t(key) : type
    },
  }
}
