import type { Camper, SearchProductsItem } from '@yescapa-dev/ysc-api-js/legacy'
import type { RouteLocationRaw } from '#vue-router'

// Problem with camper from AppBookingGuestList
type HybridCamper = Camper | SearchProductsItem | { id: Camper['id'] }

export const buildCampersIdRoute = (
  { camper, dateFrom, dateTo }:
  { camper: HybridCamper, dateFrom?: string | null, dateTo?: string | null },
) => {
  const route: RouteLocationRaw = { name: 'campers-id', params: { id: camper.id } }
  if (dateFrom && dateTo) {
    route.query ??= { date_from: dateFrom, date_to: dateTo }
    if ('hour_from' in camper && 'hour_to' in camper) {
      route.query.hour_from = camper.hour_from
      route.query.hour_to = camper.hour_to
    }
  }
  return route
}
