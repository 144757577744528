<script setup lang="ts">
import type { Picture, VehicleImageType, VehicleType } from '@yescapa-dev/ysc-api-js'

interface AppCamperCardSlideShowProps {
  items: (VehicleImageType | Picture)[]
  vehicleType?: VehicleType
  camperId?: number
}

defineProps<AppCamperCardSlideShowProps>()

const { getTranslatedPictureType } = useGetTranslatedPictureType()
</script>

<template>
  <YscSlideShow
    :items="items"
    aspect="aspect-[16/10]"
    class="rounded"
  >
    <template #default="{ activeItem }">
      <TwicImg
        :key="activeItem.id"
        :src="twicpicsPath(activeItem.path)"
        :alt="vehicleType ? `${$t('commons.booking')} - ${useVehicleType({
          type: vehicleType.toLowerCase(),
        })} - ${'vehicle_image_type' in activeItem ? getTranslatedPictureType(activeItem.vehicle_image_type): ''} - #${camperId}` : undefined"
        placeholder="maincolor"
        pre-transform="refit=auto(20p)"
        ratio="16/10"
      />
    </template>
  </YscSlideShow>
</template>
